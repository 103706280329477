import type { App } from 'vue'
import type { RouteMeta } from './routes'
import { createRouter, createWebHistory } from 'vue-router'
import { project } from '../main'
import { routes } from './routes'

export type { RouteMeta } from './routes'

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes,
})

function getDescription(projectValue: string): string {
  switch (projectValue) {
    case 'sp':
      return 'SMS Победа - рассылки без переплат!'
    case 'sd':
      return 'SMS ДАР - Инновационный сервис массовых рассылок'
    case 'zm':
    default:
      return 'Zazu Media - Эффективный мобильный маркетинг'
  }
}

router.afterEach((to) => {
  const breadcrumbs = to.meta?.breadcrumbs as RouteMeta['breadcrumbs']
  if (breadcrumbs && typeof breadcrumbs.title === 'string') {
    const title = breadcrumbs.title
    const description = getDescription(project.value)
    document.title = `${title} | ${description}`
  }
})

export default (app: App) => {
  app.router = router
  app.use(router)
}
