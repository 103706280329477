import { acceptHMRUpdate, defineStore } from 'pinia'

export const useAuthCRUDStore = defineStore('authCRUDStore', {
  state: () => ({
    userLoading: false,
  }),
})

if (import.meta.hot)
  import.meta.hot.accept(acceptHMRUpdate(useAuthCRUDStore, import.meta.hot))
