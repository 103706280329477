import Api from './api'

class AuthApi extends Api {
  URL = '/auth'

  // Активация юзера
  async activate(data: any) {
    const path = `${this.URL}/activate`
    return await super.post(path, data)
  }

  // Проверка статуса активации юзера
  async activateStatus(data: any) {
    const path = `${this.URL}/activate/status`
    return await super.post(path, data)
  }

  // Регистрация
  async register(data: any) {
    const path = `${this.URL}/register`
    return await super.post(path, data)
  }

  // Регистрация через телеграм
  async registerTelegram(data: any) {
    const path = `${this.URL}/register/telegram`
    return await super.post(path, data)
  }

  // Получение кода для восстановления
  async rectoreCode(data: any) {
    const path = `${this.URL}/restoreCode`
    return await super.post(path, data)
  }

  // Переотправка кода для восстановления
  async resendRectoreCode(data: any) {
    const path = `${this.URL}/activate/resend`
    return await super.post(path, data)
  }

  // Восстановление аккаунта
  async restore(data: any) {
    const path = `${this.URL}/restore`
    return await super.post(path, data)
  }

  // Вход
  async login(data: any) {
    const path = `${this.URL}/login`
    return await super.post(path, data)
  }

  // Выход
  async logout(data: any) {
    const path = `${this.URL}/logout`
    return await super.post(path, data)
  }

  async loginWithToken(data: any) {
    const path = `${this.URL}/withToken`
    return await super.post(path, data)
  }
}

const authApi = new AuthApi()
export default authApi
