const spPreset = {
  primitive: {
    green: {
      50: '#FBE4E4',
      100: '#F7CACA',
      200: '#EF9595',
      300: '#E76464',
      400: '#DF2E2E',
      500: '#C31E1E',
      600: '#9B1818',
      700: '#731212',
      800: '#500C0C',
      900: '#280606',
      950: '#120303',
    },
  },
  semantic: {
    primary: {
      50: '{green.50}',
      100: '{green.100}',
      200: '{green.200}',
      300: '{green.300}',
      400: '{green.400}',
      500: '{green.500}',
      600: '{green.600}',
      700: '{green.700}',
      800: '{green.800}',
      900: '{green.900}',
      950: '{green.950}',
    },
    colorScheme: {
      dark: {
        surface: {
          300: '#373737',
          400: 'rgba(223,46,46,.14)',
          500: 'rgba(223,46,46,.24)',
          600: '#e8edf2',
          700: '#e5e5e5',
          800: '#575757',
          900: '#fff',
        },
      },
    },
  },
}
export default spPreset
