export function useInitGTM() {
  const project = inject('project') as ComputedRef<'zm' | 'sp' | 'sd'>
  const gtmCodes = {
    zm: 'GTM-WCFV6F',
    sp: 'GTM-5GDRQQP',
    sd: 'GTM-TPSW92X',
  }

  function initGTM() {
    const script = document.createElement('script')
    script.setAttribute('data-gtm', 'gtm-script')
    script.innerHTML = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','${gtmCodes[project.value]}');`
    document.head.appendChild(script)

    const noScript = document.createElement('noscript')
    noScript.setAttribute('data-gtm', 'gtm-noscript')
    const iFrame = document.createElement('iframe')
    iFrame.setAttribute('src', `https://www.googletagmanager.com/ns.html?id=${gtmCodes[project.value]}`)
    iFrame.setAttribute('height', '0')
    iFrame.setAttribute('width', '0')
    iFrame.setAttribute('style', 'display:none;visibility:hidden')
    document.querySelector('body').appendChild(noScript)
    noScript.appendChild(iFrame)
  }

  function removeGTM() {
    const script = document.querySelector('script[data-gtm="gtm-script"]')
    if (script) {
      script.remove()
    }

    const noscript = document.querySelector('noscript[data-gtm="gtm-noscript"]')
    if (noscript) {
      noscript.remove()
    }
  }
  return { initGTM, removeGTM }
}
