<template>
  <FloatLabel
    class="w-full"
    :class="{ 'p-has-scroll': hasScroll && props.textarea }"
    variant="in"
  >
    <component
      :is="component"
      v-model="model"
      v-bind="bind"
      size="large"
      @blur="handleBlur"
    />
    <small :id="`${name}-help`" class="p-error">{{ errorMessage }}</small>
    <label :for="name">{{ label }}</label>
  </FloatLabel>
</template>

<script setup lang="ts">
import FloatLabel from 'primevue/floatlabel'
import InputMask from 'primevue/inputmask'
import InputNumber from 'primevue/inputnumber'
import InputText from 'primevue/inputtext'
import Textarea from 'primevue/textarea'
import { type FieldContext, useField } from 'vee-validate'

const props = defineProps({
  type: {
    type: String,
    default: 'text',
  },
  name: {
    type: String,
    default: '',
  },
  label: {
    type: String,
    default: '',
  },
  size: {
    type: String,
    default: 'large',
  },
  touched: Boolean,
  numbersOnly: Boolean,
  min: Number,
  maxlength: Number,
  phone: Boolean,
  textarea: Boolean,
  readonly: Boolean,
  disabled: Boolean,
  placeholder: String,
  mask: String,
})

const model = defineModel<string | number>()

const component = computed(() =>
  props.textarea
    ? Textarea
    : props.numbersOnly
      ? InputNumber
      : props.mask
        ? InputMask
        : InputText,
)

const textareaRef = ref(null)
const inputRef = ref(null)
defineExpose({ ref: props.textarea ? textareaRef : inputRef })
const hasScroll = ref(false)

function setupValidation(): {
  errorMessage: ComputedRef<string>
  handleBlur: () => void
  field: FieldContext
} {
  if (!props.name) {
    // Используйте computed вместо ref для создания ComputedRef
    return {
      errorMessage: computed(() => ''),
      handleBlur: () => {},
      field: null as unknown as FieldContext,
    }
  }

  const field = useField(props.name, undefined, {
    initialValue: model.value,
  })

  // Здесь errorMessage уже является ComputedRef<string>
  const errorMessage = computed(() => field.errorMessage.value)
  const handleBlur = () => field.handleBlur()

  onMounted(() => {
    nextTick(() => {
      if (props.touched && field) field.validate()
    })
  })

  return { errorMessage, handleBlur, field }
}

// Инициализация и использование vee-validate внутри setup
const { errorMessage, handleBlur, field } = setupValidation()

const bind = computed(() => ({
  [props.numbersOnly ? 'inputClass' : 'class']: {
    'p-invalid': errorMessage.value,
    'w-full !text-sm': true,
    'p-inputtext-lg': props.size === 'large',
    'p-inputtext-sm': props.size === 'small',
  },
  'ref': props.textarea ? textareaRef : undefined,
  'readonly': props.readonly, // Добавляем пропс readonly
  'disabled': props.disabled, // Добавляем пропс disabled
  'type': props.type,
  'id': props.name,
  'invalid': !!errorMessage.value,
  'aria-describedby': `${props.name}-help`,
  ...((props.min || props.min === 0) && { min: props.min }),
  ...(props.maxlength && { maxlength: props.maxlength }),
  ...((props.min || props.min === 0) && { allowEmpty: false }),
  'mask': props.mask ? props.mask : null,
  'autocomplete': 'off',
  ...(props.placeholder && { placeholder: props.placeholder }),
}))

watch(
  () => model.value,
  (newValue) => {
    nextTick(updateHasScroll)

    if (field) field.value.value = newValue
  },
)
function updateHasScroll() {
  if (textareaRef.value && textareaRef.value.$el) {
    const height
      = textareaRef.value?.$el?.scrollHeight
      - textareaRef.value?.$el?.clientHeight
    hasScroll.value = height > 5
  }
}

onMounted(() => {
  nextTick(() => {
    if (props.touched && field) field.validate()

    updateHasScroll()
  })
})
</script>

<style lang="sass" scoped>
@use '@/assets/styles/input'
</style>
