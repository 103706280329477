import type { AxiosError, AxiosRequestConfig } from 'Axios'
import ToastEventBus from 'primevue/toasteventbus'
import { request } from './config'

class Api {
  errorHandler(error: AxiosError) {
    if (error) {
      const { status, data: responseData } = error?.response
      const data: any = responseData
      if (status === 401) {
        window.location.href = `${window.location.origin}/auth`
        sessionStorage.removeItem('token')
        return
      }
      if (status === 403) {
        // Notification.warning('Нет прав на выполняемое действие');
        // Vue.$router.push({ name: 'no-access' });
        return
      }
      if (data?.errors?.length > 0) {
        // Notification.warning(error.response.data.errors.join('<br>'));
      } else {
        ToastEventBus.emit('add', {
          severity: 'error',
          summary: 'Ошибка',
          detail: 'Неизвестная ошибка',
          life: 3000,
        })
      }
    }

    console.error(error)
  }

  async get(path: string, query: string = '', customConfig?: AxiosRequestConfig, plainResponse = false) {
    try {
      const response = await request.get(`${path}${query}`, customConfig)
      if (plainResponse) return response
      else return response.data
    } catch (err: any) {
      this.errorHandler(err as AxiosError)
      throw err?.response?.data
    }
  }

  async post(path: string, data?: any, body?: any) {
    try {
      const postData = body ? data : JSON.stringify(data)
      const response = await request.post(path, postData, body)
      return response.data
    } catch (err: any) {
      this.errorHandler(err as AxiosError)
      throw err?.response?.data
    }
  }

  async delete(path: string, data?: any) {
    try {
      const response = await request.delete(path, {
        data: JSON.stringify(data),
      })
      return response.data
    } catch (err: any) {
      this.errorHandler(err as AxiosError)
      throw err?.response?.data
    }
  }

  async patch(path: string, data: any) {
    try {
      const response = await request.patch(path, JSON.stringify(data))
      return response.data
    } catch (err: any) {
      this.errorHandler(err as AxiosError)
      throw err?.response?.data
    }
  }
}

export default Api
