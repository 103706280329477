const zmPreset = {
  primitive: {
    green: {
      50: '#EEF7F1',
      100: '#E0F0E6',
      200: '#C2E1CC',
      300: '#A0D0B0',
      400: '#82C198',
      500: '#5FB07B',
      600: '#478F60',
      700: '#366D49',
      800: '#244830',
      900: '#132619',
      950: '#08110B',
    },
  },
  semantic: {
    primary: {
      50: '{green.50}',
      100: '{green.100}',
      200: '{green.200}',
      300: '{green.300}',
      400: '{green.400}',
      500: '{green.500}',
      600: '{green.600}',
      700: '{green.700}',
      800: '{green.800}',
      900: '{green.900}',
      950: '{green.950}',
    },
    colorScheme: {
      dark: {
        surface: {
          300: '#c8c8c8',
          400: 'rgba(130,193,152,.14)',
          500: 'rgba(130,193,152,.24)',
          600: '#373737',
          700: '#565656',
          800: '#575757',
          900: '#204E30',
        },
      },
    },
  },
}
export default zmPreset
