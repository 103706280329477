export function useInitVkPixel() {
  const project = inject('project') as ComputedRef

  function initVkPixel() {
    if (project.value === 'sd') {
      loadVkPixel('https://vk.com/rtrg?p=VK-RTRG-204117-i0g6Q')
    } else if (project.value === 'sp') {
      loadVkApiScript('https://vk.com/js/api/openapi.js?168', 'VK-RTRG-517048-2IEA8')
      appendVkNoscriptPixel('https://vk.com/rtrg?p=VK-RTRG-517048-2IEA8')
    } else {
      loadVkPixel('https://vk.com/rtrg?p=VK-RTRG-146266-haDFg')
    }
  }
  function loadVkPixel(src: string) {
    const script = document.createElement('script')
    script.setAttribute('data-vk', 'vk-pixel-script')
    script.type = 'text/javascript'
    script.textContent = `(window.Image ? (new Image()) : document.createElement('img')).src = '${src}';`
    document.body.appendChild(script)
  }
  function loadVkApiScript(src: string, retargetingId: string) {
    const script = document.createElement('script')
    script.setAttribute('data-vk', 'vk-api-script')
    script.type = 'text/javascript'
    script.async = true
    script.src = src
    script.onload = function () {
      VK.Retargeting.Init(retargetingId)
      VK.Retargeting.Hit()
    }
    document.head.appendChild(script)
  }
  function appendVkNoscriptPixel(src: string) {
    const noscript = document.createElement('noscript')
    noscript.setAttribute('data-vk', 'vk-noscript-pixel')
    const img = document.createElement('img')
    img.src = src
    img.style.position = 'fixed'
    img.style.left = '-999px'
    img.alt = ''
    noscript.appendChild(img)
    document.body.appendChild(noscript)
  }

  function removeVkPixel() {
    const pixelScript = document.querySelector('script[data-vk="vk-pixel-script"]')
    if (pixelScript) {
      pixelScript.remove()
    }

    const apiScript = document.querySelector('script[data-vk="vk-api-script"]')
    if (apiScript) {
      apiScript.remove()
    }

    const noscript = document.querySelector('noscript[data-vk="vk-noscript-pixel"]')
    if (noscript) {
      noscript.remove()
    }
  }
  return { initVkPixel, removeVkPixel }
}
