import type { Profile } from '@/types'
import { useGlobals } from '@/main'
import { acceptHMRUpdate, defineStore } from 'pinia'

export const useDemoUserStore = defineStore('demoUser', {
  state: () => ({
    isVisible: false,
  }),
  actions: {
    openRegForm() {
      const { $auth } = useGlobals()
      const profile = $auth.state.data as Profile

      if (profile.id === 47541) {
        this.isVisible = true
        return new Promise<boolean>(resolve => resolve(true))
      } else {
        return new Promise<boolean>(resolve => resolve(false))
      }
    },
    closeRegForm() {
      this.isVisible = false
    },
  },
})

if (import.meta.hot)
  import.meta.hot.accept(acceptHMRUpdate(useDemoUserStore, import.meta.hot))
