const sdPreset = {
  primitive: {
    green: {
      50: '#E7F1FE',
      100: '#CEE2FD',
      200: '#9EC6FA',
      300: '#6DA9F8',
      400: '#3F8DF6',
      500: '#1073F4',
      600: '#095AC3',
      700: '#074392',
      800: '#052D61',
      900: '#021631',
      950: '#010B18',
    },
  },
  semantic: {
    primary: {
      50: '{green.50}',
      100: '{green.100}',
      200: '{green.200}',
      300: '{green.300}',
      400: '{green.400}',
      500: '{green.500}',
      600: '{green.600}',
      700: '{green.700}',
      800: '{green.800}',
      900: '{green.900}',
      950: '{green.950}',
    },
    colorScheme: {
      dark: {
        surface: {
          300: '#373737',
          400: 'rgba(63,141,246,.14)',
          500: 'rgba(63,141,246,.24)',
          600: '#e8edf2',
          700: '#e5e5e5',
          800: '#575757',
          900: '#fff',
        },
      },
    },
  },
}
export default sdPreset
