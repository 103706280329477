export function useInitYandexMetrika() {
  const project = inject('project') as ComputedRef<'zm' | 'sp' | 'sd'>
  const clientId = ref<string | null>(null)

  function getMetricId() {
    if (project.value === 'sd') return 46097091
    if (project.value === 'sp') return 61756621
    return 33763184
  }

  async function getYMClientID(): Promise<string> {
    return new Promise((resolve) => {
      if (window.ym) {
        window.ym(getMetricId(), 'getClientID', (clientID: string) => resolve(clientID))
      } else if (document.cookie.includes('_ym_uid')) {
        const ymUid = document.cookie
          .split('; ')
          .find(row => row.startsWith('_ym_uid'))
          ?.split('=')[1]
        resolve(ymUid || '1111')
      } else if (document.cookie.includes('clientId')) {
        const clientIdCookie = document.cookie
          .split('; ')
          .find(row => row.startsWith('clientId'))
          ?.split('=')[1]
        resolve(clientIdCookie || '1111')
      } else {
        resolve('1111')
      }
    })
  }

  async function catchClientId() {
    clientId.value = await getYMClientID()
  }

  function initYandexMetrika() {
    (function (m, e, t, r, i, k, a) {
      m[i] = m[i] || function () {
        // eslint-disable-next-line prefer-rest-params
        (m[i].a = m[i].a || []).push(arguments)
      }
      m[i].l = 1 * new Date()
      for (let j = 0; j < document.scripts.length; j++) {
        if (document.scripts[j].src === r) {
          return
        }
      }
      k = e.createElement(t)
      a = e.getElementsByTagName(t)[0]
      k.async = 1
      k.src = r
      a.parentNode.insertBefore(k, a)
      k.setAttribute('data-ym', 'yandex-metrika')
    }(window, document, 'script', 'https://mc.yandex.ru/metrika/tag.js', 'ym'))

    const metricId = getMetricId()

    window.ym(metricId, 'init', {
      clickmap: true,
      trackLinks: true,
      accurateTrackBounce: true,
      webvisor: true,
      ecommerce: project.value !== 'zm' ? 'dataLayer' : undefined,
    })

    const noscript = document.createElement('noscript')
    noscript.setAttribute('data-ym', 'noscript-metrika')
    const div = document.createElement('div')
    const img = document.createElement('img')

    if (project.value === 'sd') {
      img.src = 'https://mc.yandex.ru/watch/46097091'
    } else if (project.value === 'sp') {
      img.src = 'https://mc.yandex.ru/watch/61756621'
    } else {
      img.src = 'https://mc.yandex.ru/watch/33763184'
    }

    img.style.position = 'absolute'
    img.style.left = '-9999px'
    img.alt = ''
    div.appendChild(img)
    noscript.appendChild(div)
    document.body.appendChild(noscript)

    // amocrm
    const scriptAmoEl = document.createElement('script')
    scriptAmoEl.src = 'https://piper.amocrm.ru/pixel/js/identifier/pixel_identifier.js'
    scriptAmoEl.id = 'amo_pixel_identifier_js'
    scriptAmoEl.async = true
    document.head.appendChild(scriptAmoEl)
  }

  function removeYandexMetrika() {
    const script = document.querySelector('script[data-ym="yandex-metrika"]')
    if (script) {
      script.remove()
    }

    const noscript = document.querySelector('noscript[data-ym="noscript-metrika"]')
    if (noscript) {
      noscript.remove()
    }
  }

  return {
    initYandexMetrika,
    removeYandexMetrika,
    catchClientId,
    clientId: readonly(clientId),
  }
}
