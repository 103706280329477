import { definePreset } from '@primevue/themes'
import Aura from '@primevue/themes/aura'

const basePreset = definePreset(Aura, {
  components: {
    datepicker: {
      dropdown: {
        background: 'var(--sidebar-bg)',
      },
      panel: {
        color: '{surface.300}',
        background: 'var(--sidebar-bg)',
      },
      header: {
        background: 'var(--sidebar-bg)',
      },
      selectMonth: {
        background: 'var(--bg-gray)',
        color: '{surface.300}',
        hoverColor: '{primary.400}',
        hoverBackground: 'var(--p-button-secondary-background)',
      },
      selectYear: {
        background: 'var(--bg-gray)',
        color: '{surface.300}',
        hoverColor: '{primary.400}',
        hoverBackground: 'var(--p-button-secondary-background)',
      },
      date: {
        color: '{surface.300}',
      },
    },
    treeselect: {
      root: {
        hoverBorderColor: '{primary.400}',
      },
      dropdown: {
        color: '{surface.300}',
      },
    },
    menu: {
      root: {
        background: 'var(--body-bg)',
        borderColor: 'transparent',
        borderRadius: '12px',
      },
      item: {
        focusBackground: 'var(--gray-DEFAULT)',
      },
    },
    accordion: {
      panel: {
        borderColor: 'transparent',
      },
      header: {
        background: 'transparent',
        hoverBackground: 'transparent',
        activeBackground: 'transparent',
        activeHoverBackground: 'transparent',
        color: '{primary.400}',
        hoverColor: '{primary.500}',
        activeColor: '{primary.400}',
        activeHoverColor: '{primary.400}',
        padding: '1rem 0',
        toggleIcon: {
          color: 'var(--text-main)',
          hoverColor: 'var(--text-main)',
          activeColor: 'var(--text-main)',
          activeHoverColor: 'var(--text-main)',
        },
      },
      content: {
        background: 'transparent',
      },
    },
    tabs: {
      tablist: {
        background: 'transparent',
      },
      tabpanel: {
        background: 'transparent',
      },
      tab: {
        color: '{surface.300}',
        hoverColor: '{primary.300}',
      },
    },
    checkbox: {
      icon: {
        checkedColor: '{surface.900}',
      },
      hoverBorderColor: '{primary.400}',
    },
    textarea: {
      background: '{surface.600}',
      hoverBorderColor: '{green.400}',
      borderColor: '{surface.700}',
      invalidBorderColor: 'var(--error-border)',
      disabledBackground: '{surface.600}',
      disabledColor: '{surface.300}',
      color: '{surface.300}',
      focusRing: {
        shadow: '0 0 0 4px {surface.400}',
      },
      paddingX: '0.875rem',
      paddingY: '1rem',
    },
    button: {
      root: {
        borderRadius: '10px',
        lg: {
          fontSize: '1rem',
          paddingY: '0.9375rem',
          paddingX: '1.25rem',
        },
        sm: {
          paddingY: '0.5rem',
          paddingX: '0.75rem',
        },
      },
      colorScheme: {
        dark: {
          root: {
            secondary: {
              background: '{surface.400}',
              hoverBackground: '{surface.500}',
              color: '{primary.400}',
              hoverColor: '{primary.400}',
              borderColor: 'transparent',
              hoverBorderColor: 'transparent',
            },
          },
          text: {
            primary: {
              hoverBackground: 'transparent',
            },
          },
        },
      },
    },
    inputtext: {
      root: {
        background: '{surface.600}',
        hoverBorderColor: '{green.400}',
        borderColor: '{surface.700}',
        invalidBorderColor: 'var(--error-border)',
        disabledColor: '{surface.300}',
        disabledBackground: '{surface.600}',
        color: '{surface.300}',
        focusRing: {
          shadow: '0 0 0 4px {surface.400}',
        },
        lg: {
          paddingX: '0.875rem',
          paddingY: '1rem',
        },
      },
    },
    floatlabel: {
      root: {
        activeColor: '{primary.400}',
        focusColor: '{primary.400}',
        color: '{surface.300}',
        fontWeight: '400',
        invalidColor: 'var(--error-border)',
      },
    },
    multiselect: {
      root: {
        hoverBorderColor: '{primary.400}',
      },
      dropdown: {
        color: '{surface.300}',
      },
    },
    select: {
      root: {
        hoverBorderColor: '{primary.400}',
      },
      dropdown: {
        color: '{surface.300}',
      },
    },
  },
})
export default basePreset
