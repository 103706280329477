<template>
  <AppModal v-model="isVisible" class="max-w-[30%] w-full" @close="closeRegForm">
    <Form
      v-slot="{ meta }"
      class="flex flex-col gap-4 p-14"
      :validation-schema="shema"
      @submit="handleSubmit"
    >
      <h3 class="text-l font-medium text-main">
        Необходима регистрация в системе
      </h3>
      <AppInput name="login" type="text" label="Телефон" />
      <AppInput name="name" type="text" label="Имя" />
      <AppButton
        type="submit"
        label="Зарегистрироваться"
        icon="pi pi-chevron-right"
        icon-pos="left"
        class="p-button-lg"
        :disabled="!meta.valid || isLoading"
        :loading="isLoading"
        severity="secondary"
      />
    </Form>
  </AppModal>
</template>

<script setup lang="ts">
import authApi from '@/api/auth'
import { useReachGoal } from '@/composables/useReachGoal'
import { useDemoUserStore } from '@/store/demoUser'
import { AppButton, AppInput, AppModal } from '@/UI'

import { validatePhone } from '@/utils/validators'
import { Form } from 'vee-validate'
import { useRoute, useRouter } from 'vue-router'
import * as yup from 'yup'

const { reachYandexMetrikaGoal } = useReachGoal()
const demoUserStore = useDemoUserStore()
const { isVisible } = storeToRefs(demoUserStore)
const { closeRegForm } = demoUserStore

const route = useRoute()
const router = useRouter()

const isLoading = ref(false)

const shema = yup.object({
  login: yup
    .string()
    .required('Необходимо заполнить это поле')
    .test('login', 'Введите корректный номер телефона', (value) => {
      return validatePhone(value)
    }),
  name: yup
    .string()
    .max(30, 'Максимальная длина 10 символов')
    .matches(/^([А-ЯЁA-Z])*$/i, 'Имя может содержать только буквы')
    .required('Необходимо заполнить это поле'),
})

function handleSubmit(values: any, actions: any) {
  isLoading.value = true

  const regData = values
  const queryData = route.query
  queryData.title = 'Регистрация через Демо ЛК'
  regData.query = queryData

  return authApi
    .register(regData)
    .then(({ success, message }) => {
      if (success) {
        reachYandexMetrikaGoal('demo')
        closeRegForm()
        router.push(`/forgotten_password?type=phone&login=${values.login}`)
      } else {
        actions.setErrors({
          login: message,
        })
      }
    })
    .finally(() => isLoading.value = false)
}
</script>
