import Api from './api'

class NewsApi extends Api {
  URL = '/news'

  async getLastNews() {
    return await super.post(`${this.URL}/last`)
  }
}

const newsApi = new NewsApi()
export default newsApi
