<template>
  <div class="display-cap" :style="gradientStyle">
    <div :style="logoStyle" class="display-cap__logo" />
  </div>
</template>

<script setup>
const project = inject('project')
const logoStyle = computed(() => {
  return {
    width: '100%',
    height: '200px',
    background: `url(${new URL(`/src/assets/img/logos/${project.value}-loading.svg`, import.meta.url).href}) no-repeat center`,
  }
})
const gradientStyle = computed(() => {
  const map = {
    sd: 'radial-gradient(73.2% 73.2% at 50% 50%, #3FF6F6 0%, #3F8DF6 100%)',
    sp: 'radial-gradient(73.2% 73.2% at 50% 50%, #E93E3E 0%, #9A3737 100%)',
    zm: 'radial-gradient(73.2% 73.2% at 50% 50%, #82c198 0%, #01491a 100%)',
  }
  return {
    background: map[project.value],
  }
})
</script>

<style lang="sass" scoped>
.display-cap
  @apply fixed left-0 top-0 flex h-screen w-full items-center justify-center z-[999]

  &__logo
    @apply h-[200px] w-full
</style>
