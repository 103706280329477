<template>
  <Button :loading="isLoading" @click="handleClick">
    <!-- Прокидываем scoped slots -->
    <template
      v-for="(slotName, i) in Object.keys($slots)"
      :key="i"
      #[slotName]="scope"
    >
      <slot :name="slotName" v-bind="scope" />
    </template>
  </Button>
</template>

<script setup lang="ts">
import Button from 'primevue/button'

const props = defineProps({
  function: {
    type: Function,
    required: false,
  },
})

const emit = defineEmits(['click'])

const isLoading = ref(false)

function handleClick(event: Event) {
  if (props.function) {
    isLoading.value = true
    props.function().finally(() => {
      isLoading.value = false
    })
  } else {
    emit('click', event)
  }
}
</script>

<style lang="sass" scoped>
.p-button
  @apply rounded-lg
  @apply border-none hover:border-none !important
  &-text
    @apply text-button-text text-sm hover:text-primary
</style>
